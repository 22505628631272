<template>
  <div :class="'product ' + (((side !== undefined) && (side === 'left'))? 'product-left': 'product-right')" :style="'background-image: url(\'' + background + '\')'">

    <div class="container">
      <template v-if="((side !== undefined) && (side === 'left') && (windowWidth >= 1024))">
        <div class="description">
          <div class="title">{{ title }}</div>
          <div class="text" v-html="text"></div>

          <template v-if="categories.length > 0">
            <div class="models-title">Модели и цены</div>
            <div class="models">
              <template v-for="(item, index) in categories">
                <button class="product-model" :key="'product-model-'+index" v-on:click="openOrderForm(item)">{{ item.name }}</button>
              </template>
            </div>
          </template>
        </div>
      </template>

      <div class="images">

        <div v-if="icons.length > 0" class="product-icons">
          <template v-for="(icon, index) in icons">
            <div :key="'icon-' + index" class="icon">
              <img :src="icon" alt="">
            </div>
          </template>
        </div>

        <hooper
          :autoPlay="false"
          :playSpeed="4000"
          :keysControl="false"
          :wheelControl="false"
          :infiniteScroll="true"
          :mouseDrag="true"
          :touchDrag="true"
          :itemsToShow="1"
          @slide="changeSlide"
        >
          <template v-for="(photo, index) in photos">
            <slide :key="'photo-' + index">
              <div class="item">
                <img :src="photo" alt="">
              </div>
            </slide>
          </template>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
        <div class="slide-counter">
          <span>0{{ slideCurrent }}</span> / 0{{ slideTotal }}
        </div>
      </div>

      <template v-if="((side === undefined) || (side === 'right') || (windowWidth < 1024))">
        <div class="description">
          <div class="title">{{ title }}</div>
          <div class="text" v-html="text"></div>

          <template v-if="categories.length > 0">
            <div class="models-title">Модели и цены</div>
            <div class="models">
              <template v-for="(item, index) in categories">
                <button class="product-model" :key="'product-model-'+index" v-on:click="openOrderForm(item)">{{ item.name }}</button>
              </template>
            </div>
          </template>
        </div>
      </template>

    </div>

    <template v-if="orderFormShow">
      <order-form :model="selectedModel" v-on:close="orderFormShow = false" v-on:after-send="showAfterSend($event)"></order-form>
    </template>

    <template v-if="afterSendWindowShow">
      <after-send-window :text="afterText" v-on:close="afterSendWindowShow = false"></after-send-window>
    </template>

  </div>
</template>

<script>

import {Hooper, Slide, Navigation as HooperNavigation} from 'hooper';

export default {

  components: {
    Hooper,
    Slide,
    HooperNavigation,
    OrderForm: () => {return import('./OrderForm')},
    AfterSendWindow: () => {return import('./AfterSendWindow')},
  },

  props: [
    'side',
    'title',
    'text',
    'categories',
    'photos',
    'icons',
    'background',
  ],

  data: function () {
    return {
      orderFormShow: false,
      afterSendWindowShow: false,
      slideCurrent: 1,
      slideTotal: 1,
      selectedModel: null,
      afterText: null,
    }
  },

  computed: {

    windowWidth: function () {
      return window.innerWidth;
    },

  },

  mounted() {
    this.slideTotal = this.photos.length;
  },

  methods: {

    changeSlide: function (e) {
      this.slideCurrent = e.currentSlide + 1;
      if (this.slideCurrent <= 0) {
        this.slideCurrent = this.slideTotal;
      }
      if (this.slideCurrent > this.slideTotal) {
        this.slideCurrent = 1;
      }
    },

    openOrderForm: function (model) {
      this.selectedModel = model;
      this.$nextTick(() => {
        this.orderFormShow = true;
      });
    },

    closeOrderForm: function () {
      this.orderFormShow = false;
      this.$nextTick(() => {
        this.selectedModel = null;
      });
    },

    showAfterSend: function (text) {
      this.afterText = text;
      this.$nextTick(() => {
        this.orderFormShow = false;
        this.afterSendWindowShow = true;
      });
    },

  },

}
</script>

<style lang="scss">
@import "./../styles/variables";

.product {
  $product_height: 634px;
  height: $product_height;
  margin-top: 100px;
  background-position: center;
  background-repeat: repeat-x;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .images {
    width: 554px;
    height: $product_height;
    background-color: $color_gray_bg;
    position: relative;
  }

  .product-icons {
    position: absolute;
    left: 40px;
    top: 40px;
    display: flex;
    flex-wrap: nowrap;
    z-index: 1;

    .icon {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .description {
    width: 584px;
    height: $product_height;
    color: #fff;
    background-color: $color_blue;
    padding: 57px 30px;

    .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      text-transform: uppercase;
      margin-bottom: 16px;
      font-family: $font_babas;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
    .models-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      margin: 42px 0 12px;
      font-family: $font_babas;
    }
    .models {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .product-model {
        font-weight: 400;
        font-size: 15px;
        line-height: normal;
        background-color: transparent;
        outline: none;
        border: 2px solid #fff;
        padding: 0 30px;
        margin-right: 30px;
        margin-bottom: 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
          background-color: #fff;
          color: $color_blue;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .slide-counter {
    position: absolute;
    left: 48px;
    bottom: 22px;
    width: 190px;
    height: 33px;
    z-index: 1;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    font-family: $font_babas;

    span {
      color: $color_blue;
    }
  }

  .hooper {
    height: $product_height;

    img {
      display: block;
    }

    .hooper-navigation {
      position: absolute;
      left: 48px;
      bottom: 24px;
      width: 190px;
      height: 33px;
      z-index: 2;
    }

    .hooper-next,
    .hooper-prev {
      width: 33px;
      height: 33px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      svg {
        display: none !important;
      }
    }

    .hooper-next {
      background-image: url('./../assets/arrow-right.svg');
    }

    .hooper-prev {
      background-image: url('./../assets/arrow-left.svg');
    }
  }

  &.product-left {
    .product-icons {
      left: auto;
      right: 40px
    }

    .hooper {
      .hooper-navigation {
        left: auto;
        right: 48px;
      }
    }

    .slide-counter {
      left: auto;
      right: 48px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .product {
    height: auto;

    .container {
      display: block;
      max-width: 554px;
    }

    .images,
    .description {
      width: 100%;
      height: auto;
    }

    .hooper {
      height: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .product {
    margin-top: 30px;

    .description {
      padding: 30px;
      .title {
        font-size: 28px;
        line-height: normal;
      }
      .models-title {
        font-size: 25px;
        line-height: normal;
      }
    }
  }
}

</style>