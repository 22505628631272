<template>
  <div id="app">
    <template v-if="isLoading">
      Загрузка...
    </template>
    <template v-else>
      <app-header></app-header>

      <template v-if="pageData.hasOwnProperty('products')">
        <template v-for="(product, index) in pageData.products">
          <div :id="'product-'+(index + 1)" :key="'product-' + index">
            <product
                :side="product.side"
                :title="product.title"
                :text="product.text"
                :categories="product.categories"
                :photos="product.photos"
                :icons="product.icons"
                :background="product.background"
            ></product>
          </div>
        </template>
      </template>

      <template v-if="pageData.hasOwnProperty('service')">
        <service :service="pageData.service"></service>
      </template>

      <template v-if="pageData.hasOwnProperty('footerForm')">
        <footer-form :form="pageData.footerForm"></footer-form>
      </template>
      <app-footer></app-footer>

      <template v-if="showOrderForm">
        <order-form></order-form>
      </template>

    </template>
  </div>
</template>

<script>
import AppHeader from "./components/Header";
import AppFooter from "./components/Footer";
import Product from "./components/Product";
import Service from "./components/Service";
import OrderForm from "./components/OrderForm";
import FooterForm from "./components/FooterForm";

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    Product,
    Service,
    OrderForm,
    FooterForm,
  },

  data: function () {
    return {
      isLoading: true,
      pageData: null,
      showOrderForm: false,
    }
  },

  mounted() {
    this.loadPageData();
  },

  methods: {

    loadPageData: function () {
      // eslint-disable-next-line no-undef
      axios.get("/page-data.json")
          .then(response => {
            if (Object.keys(response.data).length > 0) {
              this.pageData = response.data;
              this.isLoading = false
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },

  },

}
</script>

<style lang="scss" src="./styles/common.scss"></style>
