<template>
  <div>
    <header id="header">
      <div class="container">
        <div class="header-left">
          <div class="logo">
            <img src="assets/logo.svg" alt="">
          </div>
          <div class="dd-menu">
            <div class="dd-menu-title">Продукция</div>
            <div class="dd-menu-items">
              <ul>
                <li><a href="#" v-on:click.prevent="goToBlock('product-1')">Полуприцепы-цистерны</a></li>
                <li><a href="#" v-on:click.prevent="goToBlock('product-2')">Полуприцепы-тяжеловозы</a></li>
                <li><a href="#" v-on:click.prevent="goToBlock('product-3')">Бортовые полуприцепы</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header-right">
          <div class="contacts">
            <div class="phone">
              <a href="tel:88002220310">8 800 222-03-10</a>
            </div>
            <div class="phone-info">
              многоканальная линия
            </div>
          </div>
          <div class="buttons"></div>
        </div>
      </div>
    </header>
    <header id="header-mobile" :class="(menuOpen? 'active': '')">
      <div class="container">
        <div class="header-left">
          <div class="logo">
            <img src="assets/logo.svg" alt="">
          </div>
        </div>
        <div class="header-right">
          <div class="phone">
            <a href="tel:88002220310">8 800 222-03-10</a>
          </div>
          <button type="button" id="menu-toggle" :class="(menuOpen? 'active': '')" v-on:click="menuToggle">
            <span></span>
          </button>
        </div>
      </div>
    </header>
    <div id="menu-mobile" :class="(menuOpen? 'active': '')">
      <ul>
        <li><a href="#" v-on:click.prevent="goToBlock('product-1')">Полуприцепы-цистерны</a></li>
        <li><a href="#" v-on:click.prevent="goToBlock('product-2')">Полуприцепы-тяжеловозы</a></li>
        <li><a href="#" v-on:click.prevent="goToBlock('product-3')">Бортовые полуприцепы</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

  data: function () {
    return {
      menuOpen: false,
    }
  },

  methods: {

    menuToggle: function () {
      this.menuOpen = !this.menuOpen;
    },

    goToBlock: function (name) {
      this.menuOpen = false;
      let item = document.getElementById(name);
      if ((item !== undefined) && (item !== null)) {
        item.scrollIntoView({behavior: "smooth"});
      }
    },

  },

}
</script>

<style lang="scss">
@import "./../styles/variables";
#header {
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  height: 90px;
  width: 100%;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .logo {
    width: 215px;
  }

  .header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .contacts {
    .phone {
      font-size: 22.4px;
      font-family: $font_babas;
      font-weight: bold;
      line-height: 19px;

      a {
        color: $color_dark;
        text-decoration: none;
      }
    }
    .phone-info {
      font-size: 11px;
      line-height: 11px;
    }
  }

  .dd-menu {
    position: relative;
    font-family: $font_babas;
    margin-left: 42px;
    z-index: 1;

    .dd-menu-title {
      text-transform: uppercase;
      color: #2e2e2e;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
    }
    .dd-menu-items {
      display: none;
      position: absolute;
      left: -18px;
      padding-top: 18px;
      width: 336px;

      ul {
        margin: 0;
        padding: 18px;
        list-style: none;
        background-color: $color_blue;

        li {
          margin: 0 0 12px;
          padding: 0;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: #fff;
            text-transform: uppercase;
            display: block;
            transition: color 0.3s ease-in-out;

            &:hover {
              color: #98acf3;
            }
          }
        }
      }
    }

    &:hover {
      .dd-menu-title {
        color: #8d8c8c;
      }
      .dd-menu-items {
        display: block;
      }
    }
  }

  .contacts {

  }

  .buttons {

  }
}

#header-mobile {
  position: relative;
  z-index: 1001;
  display: none;
}

#menu-toggle {
  $size: 65px;
  width: $size;
  height: $size;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;

  span {
    $burger_width: 30px;
    $burger_height: 4px;
    $burger_offset: 6px;
    display: block;
    position: absolute;
    left: ceil(($size - $burger_width) / 2);
    top: ceil(($size - $burger_height) / 2);
    background-color: $color_blue;
    width: $burger_width;
    height: $burger_height;
    transition: background-color 0.3s ease-in-out;

    &:before,
    &:after {
      content: '';
      width: $burger_width;
      height: $burger_height;
      display: block;
      position: absolute;
      left: 0;
      background-color: $color_blue;
      transition: all 0.3s ease-in-out;
    }

    &:before {
      top: -($burger_offset + $burger_height);
    }

    &:after {
      bottom: -($burger_offset + $burger_height);
    }
  }

  &.active {
    span {
      background-color: transparent;

      &:before {
        transform: rotate(45deg);
        top: 0;
      }

      &:after {
        transform: rotate(-45deg);
        bottom: 0;
      }
    }
  }
}

#menu-mobile {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $color_blue;
  color: #fff;
  display: none;
  padding-left: 15px;
  padding-right: 15px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: block;
      transition: color 0.3s ease-in-out;
      font-size: 18px;
      font-family: $font_babas;
      color: #fff;
      margin-bottom: 15px;

      a {
        color: #fff;
        text-decoration: none;

      }

    }
  }
}

@media screen and (max-width: 1023px) {
  #header {
    display: none;
  }
  #header-mobile {
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
    height: auto;
    display: block;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .header-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .logo {
      width: 215px;
    }

    .phone {
      font-size: 22.4px;
      font-family: $font_babas;
      font-weight: bold;
      line-height: 19px;
      margin-right: 10px;
      white-space: nowrap;

      a {
        color: $color_dark;
        text-decoration: none;
      }
    }

  }

  #menu-mobile {
    display: block;
    opacity: 0;
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding-top: 80px;

    &.active {
      left: 0;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 480px) {
  #header-mobile {
    .logo {
      width: 130px;
    }
    .phone {
      font-size: 19px;
    }
  }
  #menu-toggle {
    $size: 44px;
    width: $size;
    height: $size;

    span {
      $burger_width: 24px;
      $burger_height: 2px;
      $burger_offset: 5px;
      width: $burger_width;
      height: $burger_height;
      left: ceil(($size - $burger_width) / 2);
      top: ceil(($size - $burger_height) / 2);

      &:before,
      &:after {
        width: $burger_width;
        height: $burger_height;
      }

      &:before {
        top: -($burger_offset + $burger_height);
      }

      &:after {
        bottom: -($burger_offset + $burger_height);
      }
    }
  }

  #menu-mobile {
    padding-top: 59px;
  }
}

</style>