<template>
  <div class="footer-form">
    <div class="container">
      <div class="form-left">
        <div class="form-top">
          <div class="title">{{ form.title }}</div>
          <div class="text" v-html="form.text"></div>
        </div>
        <div class="form-bottom">
          <div class="title">{{ form.stepsTitle }}</div>
          <ol>
            <template v-for="(step, index) in form.steps">
              <li :key="'step-'+index">{{ step }}</li>
            </template>
          </ol>
        </div>
      </div>
      <div class="form-right">
        <div class="title">
          Заявка на расчет лизинга
        </div>
        <form action="form.php" method="post" class="form" v-on:submit.prevent="sendForm">
          <input type="text" v-model="name" placeholder="Ваше имя" required>
          <input type="text" v-model="phone" placeholder="Телефон" v-mask="'+7 (###) ###-##-##'" required ref="phone">
          <input type="text" v-model="inn" placeholder="ИНН" required>
          <div class="policy">
            <input type="checkbox" id="policy-footer" v-model="policy">
            <label for="policy-footer">
              согласен на обработку <a href="https://chmz.org/soglasie-na-obrabotku-personalnykh-dannykh/" target="_blank">персональных данных</a>
            </label>
          </div>
          <button type="submit" class="submit">Отправить</button>
        </form>
      </div>
    </div>

    <template v-if="showAfter">
      <after-send-window :text="showAfterText" v-on:close="showAfter = false"></after-send-window>
    </template>

  </div>
</template>

<script>
export default {

  components: {
    AfterSendWindow: () => {return import('./AfterSendWindow')},
  },

  props: [
    'form',
  ],

  data: function () {
    return {
      name: null,
      phone: null,
      inn: null,
      policy: true,

      showAfter: false,
      showAfterText: null,
    }
  },

  methods: {

    sendForm: function () {

      if (this.phone.length !== 18) {
        this.$refs.phone.focus();
        return false;
      }

      // eslint-disable-next-line no-undef
      axios.post("form.php", {
        form: 'leasing',
        policy: this.policy,
        name: this.name,
        phone: this.phone,
        inn: this.inn,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            //console.log(response);
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('status') && (response.data.status === true)) {
              this.showAfterText = response.data.message;
              this.$nextTick(() => {
                this.name = null;
                this.inn = null;
                this.phone = '+7 (';
                this.showAfter = true;
              })
              //this.$emit('after-send', response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },

  },

}
</script>

<style lang="scss">
@import "./../styles/variables";

$form_width: 460px;

.footer-form {

  .container {
    display: flex;
  }

  .form-left {
    width: 100%;

    .form-top,
    .form-bottom {
      padding: 40px;
    }

    .form-top {
      .title {
        font-weight: 700;
        font-size: 48px;
        font-family: $font_babas;
        margin-bottom: 20px;
      }
      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;

        p {
          &:first-child{
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .form-bottom {
      background-color: $color_blue;
      color: #fff;

      .title {
        font-weight: 700;
        font-size: 30px;
        font-family: $font_babas;
        margin-bottom: 20px;
      }

      ol {
        padding: 0 0 0 20px;
        margin: 0;

        li{
          margin-bottom: 18px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .form-right {
    width: $form_width;
    min-width: $form_width;
    background-color: $color_gray_bg;
    padding: 40px;
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
    justify-content: center;

    .form {
      width: 100%;

      input[type="text"] {
        width: 100%;
        background-color: #fff;
        height: 50px;
        padding: 0 20px;
        border: none;
        margin-bottom: 20px;
        outline: none;
      }
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      color: $color_blue;
      font-family: $font_babas;
      margin-bottom: 24px;
    }

    .submit {
      margin-top: 30px;
      height: 50px;
      width: 100%;
      display: block;
      background-color: transparent;
      border: 2px solid $color_blue;
      color: $color_blue;
      font-family: $font_babas;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

      &:hover {
        background-color: $color_blue;
        color: #fff;
      }
    }

  }

}

@media screen and (max-width: 1023px) {
  .footer-form {

    .form-left,
    .form-right {
      width: 50%;
    }

    .form-right {
      min-width: auto;
    }

  }
}

@media screen and (max-width: 767px) {
  .footer-form {
    .container {
      flex-wrap: wrap;
    }
    .form-left,
    .form-right {
      width: 100%;
    }

  }
}

@media screen and (max-width: 480px) {
  .footer-form {
    .form-left {
      .form-top,
      .form-bottom {
        padding: 30px;
      }

      .form-top {
        .title {
          font-weight: 700;
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

</style>