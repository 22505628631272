<template>
  <div>
    <div class="container">
      <div id="service">
        <div class="info">
          <div class="title">{{ service.header }}</div>
          <div class="text">{{ service.text }}</div>
        </div>
        <div class="phone">
          <div class="text">Единый номер:</div>
          <div class="value">
            <a href="tel:88002220310">8-800-222-03-10</a>
          </div>
        </div>
      </div>
    </div>
    <div id="map-categories">
      <div class="container">
        <a :class="(selectedCategory == null)? 'active': ''" href="#" v-on:click.prevent="changeCategory(null)">Все</a>
        <template v-for="(category, index) in service.map.categories">
          <a :key="'category-' + index" :class="(selectedCategory === category)? 'active': ''" href="#" v-on:click.prevent="changeCategory(category)">{{ category }}</a>
        </template>
      </div>
    </div>
    <div id="map" :style="'height:'+mapHeight+'px'">
      <yandex-map
          @map-was-initialized="mapLoaded"
          :settings="settings"
          :coords="service.map.center"
          :zoom="service.map.zoom"
          :controls="['zoomControl']"
          :scroll-zoom="false"
          :zoom-control="{visible: true}"
          :options="{suppressMapOpenBlock: true}">

        <template v-if="showMarkers && (markers.length > 0)">
          <template v-for="(marker, markerIndex) in markers">
            <ymap-marker
                :key="'marker-' + markerIndex"
                :marker-id="'marker' + markerIndex"
                :coords="marker.coords"
                :balloon="{
                  header: marker.name,
                  body: marker.address,
                  footer: marker.phone
                }"
            />
          </template>
        </template>
      </yandex-map>
    </div>
  </div>
</template>

<script>

import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {

  components: {
    yandexMap,
    ymapMarker,
  },

  props: [
    "service",
  ],

  data: function () {
    return {
      markers: [],
      showMarkers: false,

      settings: {
        apiKey: '9b3d6382-7b63-4170-abba-09bcaa4e72df',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },

      selectedCategory: null,
    }
  },

  computed: {

    mapHeight: function () {
      if (window.innerWidth < 480) {
        return 320;
      } else if (window.innerWidth < 1024) {
        return 400;
      }
      return 590;
    }

  },

  mounted() {
    this.makeMarkerList();
  },

  methods: {

    mapLoaded: function () {

    },

    changeCategory: function (category) {
      this.selectedCategory = category;
      this.showMarkers = false;
      this.$nextTick(() => {
        this.makeMarkerList();
      });
    },

    makeMarkerList: function () {
      if (this.selectedCategory == null) {
        this.markers = this.service.map.markers;
      } else {
        if (this.service.map.markers.length > 0) {
          let items = [];
          for (let index in this.service.map.markers) {
            let item = this.service.map.markers[index];
            // eslint-disable-next-line no-prototype-builtins
            if ((item !== undefined) && (item !== null) && item.hasOwnProperty('categories') && (item.categories.length > 0)) {
              if ((item.categories.indexOf(this.selectedCategory) !== -1)) {
                items.push(item);
              }
            }
          }
          this.markers = items;
        }
      }
      this.showMarkers = true;
    },

  },

}
</script>

<style lang="scss">
.ymap-container {
  height: 100%;
}
</style>

<style lang="scss">
@import "./../styles/variables";

#service {
  display: flex;
  //align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 30px;

  .info,
  .phone {
    //height: 100%;
    padding: 70px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .info {
    background-color: $color_blue;
    width: 749px;
    color: #fff;
    justify-content: flex-start;

    .title {
      font-family: $font_babas;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 10px;
      text-transform: uppercase;
      width: 100%;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      width: 100%;
    }
  }

  .phone {
    background-color: $color_gray_bg;
    width: 389px;
    justify-content: center;

    .text {
      font-size: 30px;
      font-family: $font_babas;
      color: $color_dark;
      text-transform: uppercase;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .value {
      font-size: 40px;
      font-family: $font_babas;
      color: $color_blue;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;

      a {
        text-decoration: none;
        color: $color_blue;
      }
    }
  }
}

#map-categories {
  font-size: 0;
  margin-bottom: 17px;

  a {
    display: inline-block;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $font_babas;
    color: $color_dark;
    border-bottom: 2px solid transparent;
    transition: border 0.3s ease-in-out, color 0.3s ease-in-out;
    margin-right: 68px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $color_blue;
      border-color: $color_blue;
    }
  }
}

#map {
  background-color: $color_gray;
}

@media screen and (max-width: 1023px) {
  #service {
    display: block;

    .info,
    .phone {
      width: 100%;
      padding: 40px;
    }
  }

  #map-categories {
    a {
      margin-right: 30px;
      margin-bottom: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #service {
    .info,
    .phone {
      width: 100%;
      padding: 30px;
    }
    .info {
      .title {
        font-size: 28px;
        line-height: normal;
      }
    }
  }
}

</style>