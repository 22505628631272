<template>
  <div>
    <div class="bg-splash">
      <form class="form modal-window" method="post" action="form.php" enctype="multipart/form-data" ref="form" v-on:submit.prevent="sendForm">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-title">{{ model.formName }}</div>
        <div class="modal-text">{{ model.formText }}</div>

        <div class="fields">

          <input type="hidden" name="form" :value="model.formName">
          <input type="hidden" name="model" :value="model.name">

          <div class="modal-row">
            <div class="modal-cell">
              <div class="input-field">
                <label for="liq">Перевозимая жидкость</label>
                <div class="select-box">
                  <select name="liquid" id="liq" required v-model="liquid">
                    <option>Светлые нефтепродукты</option>
                    <option>Питьевая вода</option>
                    <option>Вода</option>
                    <option>Техвода</option>
                  </select>
                </div>
                <div class="input-info">
                  Разные среды имеют разную плотность значит разную массу при одинаковом объеме. Это необходимо учитывать при расчете.
                </div>
              </div>
            </div>
            <div class="modal-cell">
              <div class="input-field">
                <label for="vol">Объем цистерны</label>
                <div class="select-box">
                  <select name="volume" id="vol" required v-model="volume">
                    <option>16 000</option>
                    <option>18 000</option>
                    <option>20 000</option>
                    <option>22 000</option>
                    <option>24 000</option>
                    <option>26 000</option>
                    <option>28 000</option>
                    <option>30 000</option>
                    <option>32 000</option>
                    <option>34 000</option>
                    <option>36 000</option>
                    <option>38 000</option>
                    <option>40 000</option>
                  </select>
                </div>
                <div class="input-info">Объем, наряду с плотностью определяет массу перевозимой жидкости и массу стали в цистерне полуприцепа.</div>
              </div>
            </div>
          </div>

          <div class="modal-row">
            <div class="modal-cell">
              <div class="input-field">
                <label for="base">Тягач</label>
                <input type="text" id="base" name="base" required v-model="base">
                <div class="input-info">Тягач определяет допустимую нагрузку на седло, высоту ССУ и т.д. (уточнить)</div>
              </div>
            </div>
            <div class="modal-cell">
              <div class="input-field">
                <label for="email">Ваш e-mail</label>
                <input type="email" id="email" placeholder="Укажите E-mail" required v-model="email">
                <div class="input-info">Подготовка списка и расчет актуальных цен на каждую модель займет около 3 часов. По готовности мы вышлем результат на Вашу электронную почту.</div>
              </div>
            </div>
          </div>

          <div class="modal-row">
            <div class="modal-cell">
              <div class="input-field">
                <label for="phone">Ваш номер телефона</label>
                <input type="text" name="phone" required id="phone" v-mask="'+7 (###) ###-##-##'" value="+7 (" placeholder="Введите телефон" v-model="phone" ref="phone">
                <div class="input-info">Если возникнут дополнительные вопросы укажите по какому номеру мы сможем уточнить информацию</div>
              </div>
            </div>
            <div class="modal-cell">
              <div class="input-field">
                <label for="tech">Техническое задание</label>
                <div class="file-box">
                  <input type="file" name="file" id="tech" @change="onFileChange">
                  <span id="filename">{{ (filename == null)? 'Прикрепить': filename }}</span>
                </div>
                <div class="input-info">Если у Вас есть готовое техническое задание или подходящее коммерческое предложение, то прикрепите его.</div>
              </div>
            </div>
          </div>

        </div>

        <div class="submit">

          <div class="policy">
            <input type="checkbox" name="policy" v-model="policy" id="order-policy" required>
            <label for="order-policy">
              согласен на обработку <a href="https://chmz.org/soglasie-na-obrabotku-personalnykh-dannykh/" target="_blank">персональных данных</a>
            </label>
          </div>

          <button type="submit">Получить</button>

        </div>

        <div class="after-form">
          После подготовки мы пришлем список моделей полуприцепов, оптимально подходящих под Ваши требования.<br> Список возможных опций и доработок с указанием актуальных на день подготовки цен.
        </div>

      </form>
    </div>

  </div>
</template>

<script>
export default {

  props: [
    'model',
  ],

  data: function () {
    return {

      liquid: null,
      volume: null,
      base: null,
      phone: '+7 (',
      email: null,

      policy: true,
      filename: null,
    }
  },

  methods: {

    sendForm: function () {

      if (this.phone.length !== 18) {
        this.$refs.phone.focus();
        return false;
      }

      // TODO валидация формы
      var formData = new FormData();
      var files = document.getElementById('tech');
      formData.append("form", 'order');
      formData.append("policy", this.policy);
      formData.append("liquid", this.liquid);
      formData.append("volume", this.volume);
      formData.append("base", this.base);
      formData.append("phone", this.phone);
      formData.append("email", this.email);

      if ((files !== undefined) && (files !== null) && (files.files.length > 0)) {
        console.log(files.files[0]);
        formData.append("file", files.files[0]);
      }

      //console.log(formData);
      //console.log(files);
      //return false;

      //axios.post("https://jsonplaceholder.typicode.com/posts", formData, {
      // eslint-disable-next-line no-undef,no-unreachable
      axios.post("/form.php", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('status') && (response.data.status === true)) {
              //
              this.$emit('after-send', response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            alert(error);
          });
    },

    onFileChange: function (event) {
      let fileData =  event.target.files[0];
      this.filename = fileData.name;
    },

    closeModal: function () {
      this.$emit('close');
    },

  },

}
</script>

<style lang="scss" scoped>
@import "./../styles/variables";

$input_height: 50px;

.form {
  width: 944px;

  .title {

  }

  .fields {
    .input-field {
      width: 100%;
      margin-bottom: 20px;

      label {
        margin-bottom: 8px;
      }

      input,
      select {
        width: 100%;
        height: $input_height;
        background-color: #fff;
        padding: 0 20px;
        font-weight: 400;
        font-size: 14px;
        color: $color_dark;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #A3A3A3;
        }
      }
    }
  }

  .submit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-family: $font_babas;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      height: $input_height;
      border: 2px solid $color_blue;
      color: $color_blue;
      padding: 0 15px;
      width: 100%;
      max-width: 388px;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        background-color: $color_blue;
        color: #fff;
      }
    }
  }

  .after-form {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 33px;
  }
}

.file-box{
  height: $input_height;
  background-color: #fff;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &:after {

  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #A3A3A3;
    position: absolute;
    z-index: 4;
    top: 12px;
    left: 20px;
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    cursor: pointer;
  }
}

.policy input + label {
  font-size: 14px;
}

@media screen and (max-width: 1023px) {
  .form {
    max-width: 840px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .modal-window {
    .modal-row {
      display: block;

      .modal-cell {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .form {
    .submit {
      display: block;

      .policy {
        margin-bottom: 20px;
      }
    }
  }

}
</style>