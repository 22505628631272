<template>
  <footer id="footer">
    <div class="container">
      <div class="copyrights">
        ООО «Челябинский Машиностроительный Завод»<br>
        454038, г. Челябинск, ул. Промышленная, 6
      </div>
      <div class="contacts">
        <div class="phone">
          <div class="value">
            <a href="tel:88002220310">8 800 222-03-10</a>
          </div>
          <div class="info">многоканальная линия</div>
        </div>
        <div class="text">
          Электронная почта: <a href="mailto:zakaz@chmz.org">zakaz@chmz.org</a><br>
          Отдел кадров : <a href="mailto:personal@chmz.org">personal@chmz.org</a>
        </div>
      </div>
      <div class="links">
        <div class="item">
          <a href="https://vk.com/chmzspeztexnika" target="_blank" class="vk"><span>Группа в VK</span></a>
        </div>
        <div class="item">
          <a href="https://www.youtube.com/channel/UCrgFfs1OCrEJh_9QPxJZXXQ/videos" target="_blank" class="youtube"><span>Канал в Youtube</span></a>
        </div>
        <!--<div class="item">
          <a href="mailto:" class="email"><span>Написать письмо</span></a>
        </div>-->
        <div class="item">
          <a href="#" class="callback" v-on:click.prevent="callbackShow = true"><span>Обратный звонок</span></a>
        </div>

        <template v-if="callbackShow">
          <callback-form v-on:close="closeModal" v-on:after-send="showAfterSend($event)"></callback-form>
        </template>

        <template v-if="afterSendWindowShow">
          <after-send-window :text="afterText" v-on:close="afterSendWindowShow = false"></after-send-window>
        </template>

      </div>
    </div>
  </footer>
</template>

<script>

export default {

  components: {
    CallbackForm: () => {return import('./CallbackForm')},
    AfterSendWindow: () => {return import('./AfterSendWindow')},
  },

  data: function () {
    return {

      callbackShow: false,
      afterText: null,
      afterSendWindowShow: false,

    }
  },

  methods: {

    closeModal: function () {
      this.callbackShow = false;
    },

    showAfterSend: function (text) {
      this.afterText = text;
      this.$nextTick(() => {
        this.callbackShow = false;
        this.afterSendWindowShow = true;
      });
    },

  }

}
</script>

<style lang="scss" scoped>
@import "./../styles/variables";

#footer {
  padding: 30px 0;
  color: #494949;
  border-top: 1px solid #dfdfdf;

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .copyrights,
  .contacts,
  .links {
    width: 25%;
  }

  .copyrights {
    font-size: 13px;
    margin-right: 5%;
  }

  .contacts {
    .phone {

      .value {
        font-size: 22.4px;
        font-family: $font_babas;
        font-weight: bold;
        line-height: 19px;

        a {
          color: $color_dark;
          text-decoration: none;
        }
      }

      .info {
        font-size: 11px;
        line-height: 11px;
      }

    }

    .text {
      font-size: 12px;
      margin-top: 10px;

      a{
        color: #3955b8;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .links {
    font-size: 12px;

    .item {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: #2e2e2e;
      text-decoration: none;
      display: inline-block;
      font-weight: bold;
      padding-left: 30px;
      background-position: left center;
      background-repeat: no-repeat;

      span {
        border-bottom: 1px dotted #969696;
        transition: border 0.3s ease-in-out;
      }

      &:hover {
        span {
          border-bottom: 1px dotted transparent;
        }
      }

      &.vk {
        background-image: url('./../assets/vk.png');
      }

      &.youtube {
        background-image: url('./../assets/youtube.png');
      }

      &.email {
        background-image: url('./../assets/email.png');
      }

      &.callback {
        background-image: url('./../assets/phone.png');
      }
    }
  }

}

@media screen and (max-width: 1023px) {
  #footer {
    .container {
      flex-wrap: wrap;
      justify-content: center;
    }

    .copyrights {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      text-align: center;
    }

    .contacts,
    .links {
      width: auto;
      margin: 0 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  #footer {

    .container {
      justify-content: space-between;
    }

    .copyrights {
      text-align: left;
    }

    .contacts {
      margin: 0 10px 20px 0;
    }
    .links {
      margin: 0;
    }
  }
}

</style>